import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScanTicket from "./pages/ScanTicket";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    // <div className="relative flex-col justify-center w-full h-full">
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "#e5e7eb",
      }}
    >
      <Router>
        <Routes>
          <Route exact path="/:eventId" element={<ScanTicket />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
