import { useState, useLayoutEffect, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, CardHeader, CardContent } from "../..//components/ui/card";
import {
  CheckIcon,
  Cross1Icon,
  ExclamationTriangleIcon,
} from "@radix-ui/react-icons";
import QrScanner from "qr-scanner";
import https from "../../lib/https";
import TicketIcon from "../../assets/icons/Ticket";
import ProfileIcon from "../../assets/icons/Profile";
import { cn } from "../../lib/utils";
import Event from "./Event";
import scanImage from "../../assets/images/scan-area.png";
import scanButtonGray from "../../assets/images/scan-circle-outline.png";
import scanButtonPurple from "../../assets/images/scan-circle-outline-purple.png";
import { Scanner } from "@yudiel/react-qr-scanner";
import { useNavigate } from "react-router-dom";
import ScanButton from "../../components/common/ScanButton";

function formatBirthdate(utcDateString) {
  const date = new Date(utcDateString);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

const ScanTicket = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [eventLoading, setEventLoading] = useState(true);
  const [showLanding, setShowLanding] = useState(false);
  const [scan, setScan] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [ticketStatus, setTicketStatus] = useState(null);
  const [ticketData, setTicketData] = useState(null);
  const [hasError, setHasError] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const qrScannerRef = useRef(null);
  const [selected, setSelected] = useState("invitados"); // default selected button

  const navigate = useNavigate();

  const fetchEvent = async () => {
    try {
      setLoading(true);
      const response = await https.get(`/api/v1/staff/tickets/${eventId}`);
      const { tickets, event } = response.data.data;
      setEvent(event);
      setTickets(tickets);
      setEventLoading(false);
      setLoading(false);
    } catch (error) {
      setHasError(true);
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEvent();
  }, [eventId]);

  useEffect(() => {
    if (scan && videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" } })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          qrScannerRef.current = new QrScanner(
            videoRef.current,
            (result) => {
              console.log("QR Data:", result.data);
              if (result.data && result.data.length > 0) {
                handleScan(result.data, result);
              }
            },
            { returnDetailedScanResult: true }
          );
          qrScannerRef.current.start();
        })
        .catch((error) => {
          console.error("Error accessing camera:", error);
        });
    }

    return () => {
      qrScannerRef.current?.stop();
    };
  }, [scan, videoRef, loading, ticketData]);

  // useEffect(() => {
  //   console.log("useEffect:", scan, videoRef);
  //   if (scan && videoRef.current) {
  //     try {
  //       const qrScanner = new QrScanner(
  //         videoRef.current,
  //         (result) => {
  //           console.log("scan:", scan);
  //           handleScan(result.data);
  //         },
  //         {
  //           returnDetailedScanResult: true,
  //         }
  //       );
  //       console.log("qrScanner:", qrScanner);
  //       qrScanner.start();
  //       qrScannerRef.current = qrScanner;

  //       // return () => qrScanner.stop();
  //     } catch (error) {
  //       console.log("error:", error);
  //     }
  //   }
  // }, [scan, videoRef, loading, ticketData]);

  const handleScan = async (ticketId, result) => {
    try {
      console.log("qrCode:", ticketId, result, eventId);
      if (ticketData || loading) return;
      setLoading(true); // Show loading spinner
      qrScannerRef.current?.stop(); // Stop scanning

      const response = await https.post("/api/v1/staff/scan-ticket", {
        ticketId,
        eventId,
        result: result,
      });
      console.log("response:", response);
      tickets.forEach((ticket) => {
        if (ticket._id === ticketId) {
          ticket.status = "scanned";
        }
      });
      setTicketStatus(
        response?.data?.data?.previouslyScanned ? "warning" : "success"
      );

      // setTicketStatus("warning");
      console.log(
        "ticketTransaction",
        response?.data?.data?.ticketTransaction?.redemption_code_used
      );
      setTicketData({
        ...response?.data?.data?.ticket,
        isGuestList:
          response?.data?.data?.ticketTransaction?.redemption_code_used &&
          response?.data?.data?.ticketTransaction?.redemption_code_used !== "",
      });

      setLoading(false); // Hide loading spinner
    } catch (error) {
      console.log("error:", error, error.response);
      alert(error);
      setTicketStatus("error");
      setTicketData(error);
      setError(error?.response?.data?.error);
      setLoading(false); // Hide loading spinner
    }
  };

  function TicketStats() {
    // Calculate total tickets and breakdown by tickettier
    const ticketStats = tickets.reduce(
      (acc, ticket) => {
        const tierId = ticket._tickettier._id;
        const tierName = ticket._tickettier.name;
        const ticketCount =
          ticket._tickettier.total_ticket_quantity -
          ticket._tickettier.sold_ticket_quantity;
        const price = ticket._tickettier.price;
        const ticketsPerPurchase = ticket._tickettier.ticketsPerPurchase || 1; // Fallback to 1 if not provided

        // Increment the total ticket count
        acc.total_tickets += 1;

        // Find or add the tier in the breakdown array
        const tier = acc.breakdown.find((t) => t.tier_id === tierId);
        if (tier) {
          // If tier exists, increment the ticket count
          tier.ticket_count += 1;
        } else {
          // If not, add a new tier entry
          acc.breakdown.push({
            tier_id: tierId,
            tier_name: tierName,
            ticket_count: 1,
            price,
            ticketsPerPurchase,
            availableTickets: ticketCount,
          });
        }
        return acc;
      },
      { total_tickets: 0, breakdown: [] }
    );

    return (
      <div
        style={{
          padding: "20px",
          maxWidth: "400px",
          marginTop: "20px",
          marginLeft: "20px",
          marginRight: "20px",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          overflow: "scroll",
          paddingBottom: "80px",
        }}
      >
        <h2
          style={{
            color: "#4a4a4a",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Estadísticas de Tickets
        </h2>
        <div
          style={{
            marginBottom: "10px",
            fontSize: "1.2em",
            fontWeight: "bold",
            color: "#333",
          }}
        >
          Total Tickets:{" "}
          <span style={{ color: "#7C5DF8" }}>{ticketStats.total_tickets}</span>
        </div>
        <div>
          <h3
            style={{
              color: "#4a4a4a",
              fontSize: "1.1em",
              marginBottom: "10px",
            }}
          >
            Desglose por Categoría
          </h3>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {ticketStats.breakdown.map((tier) => (
              <li
                key={tier.tier_id}
                style={{
                  marginBottom: "16px",
                  padding: "10px",
                  borderRadius: "6px",
                  backgroundColor: "#eaeaea",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  <span>{tier.tier_name}</span>
                  <span style={{ color: "#7C5DF8" }}>
                    {tier.ticket_count} boletos
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "8px",
                    fontSize: "0.9em",
                    color: "#555",
                  }}
                >
                  <div>Precio: ${(tier.price / 100).toLocaleString()}</div>
                  <div>Tickets por compra: {tier.ticketsPerPurchase}</div>
                  <div>Disponibles: {tier.availableTickets}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  if (eventLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
      </div>
    );
  }

  if (hasError) {
    return (
      <div className="h-full flex items-center justify-center">
        <p className="text-red-500">
          An error occurred. Please try again later.
        </p>
      </div>
    );
  }

  // if (showLanding) {
  //   return (
  //     <Event
  //       onClick={() => {
  //         setShowLanding(false);
  //         setScan(true);
  //       }}
  //     />
  //   );
  // }

  console.log("ticket:", ticketStatus, ticketData);

  if (ticketStatus === "error") {
    return (
      <div className="h-full">
        <div
          className="relative w-[90%] h-[84%] mx-auto rounded-lg mt-4 flex"
          style={{
            border: "6px solid white",
            borderRadius: "20px",
            backgroundColor: "#EB4335",
          }}
        >
          <div className="absolute top-0 left-0 w-full" style={{ zIndex: 100 }}>
            <div class="border border-zinc-200 dark:border-zinc-800 dark:bg-zinc-950 dark:text-zinc-50 py-5 text-white bg-gray-800 shadow-md rounded-[20px] w-[80%] mx-auto mt-4">
              <div class="flex flex-col items-center gap-4 p-0 text-center">
                <h3 class="text-xl font-semibold text-center truncate w-full max-w-[200px]">
                  {event.title}
                </h3>
              </div>
            </div>
          </div>
          <div className="w-full" style={{ marginTop: "100px" }}>
            <div
              style={{ backgroundColor: "white" }}
              className="flex mt-3 items-center justify-center w-20 h-20 rounded-full p-4 mx-auto"
            >
              <Cross1Icon className="w-16 h-16" style={{ color: "#EB4335" }} />
            </div>{" "}
            <div className="flex items-center gap-4 py-2 px-4 mt-4">
              <div className="w-full text-black text-center">
                <p className="text-xs font-bold">{error}</p>{" "}
              </div>
            </div>
          </div>

          {/* Centered button container */}
          <div className="absolute bottom-[-10%] left-1/2 transform -translate-x-1/2 w-32 h-32 rounded-full bg-white z-[3] flex justify-center items-center">
            <button
              className="p-0 border-none bg-transparent focus:outline-none w-[80%]"
              onClick={() => {
                setScan(true);
                setTicketData(null);
                setTicketStatus("");
              }}
            >
              <img
                src={scanButtonGray}
                alt="Scan button"
                className="w-full h-full"
              />
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (ticketStatus === "warning") {
    return (
      <div className="h-full">
        <div
          className="relative w-[90%] h-[84%] mx-auto rounded-lg mt-4 flex"
          style={{
            border: "6px solid white",
            borderRadius: "20px",
            backgroundColor: "#E89117",
          }}
        >
          <div className="absolute top-0 left-0 w-full" style={{ zIndex: 100 }}>
            <div class="border border-zinc-200 dark:border-zinc-800 dark:bg-zinc-950 dark:text-zinc-50 py-5 text-white bg-gray-800 shadow-md rounded-[20px] w-[80%] mx-auto mt-4">
              <div class="flex flex-col items-center gap-4 p-0 text-center">
                <h3 class="text-xl font-semibold text-center truncate w-full max-w-[200px]">
                  {event.title}
                </h3>
              </div>
            </div>
          </div>

          <div className="w-full" style={{ marginTop: "100px" }}>
            <div
              style={{ backgroundColor: "white" }}
              className="flex mt-3 items-center justify-center w-20 h-20 rounded-full p-4 mx-auto"
            >
              <ExclamationTriangleIcon
                className="w-16 h-16"
                style={{ color: "#E89117" }}
              />
            </div>{" "}
            <div className="w-full text-black bg-white p-2 mt-2">
              <p className="text-2xl font-bold mx-auto text-center">
                boleto ya escaneado
              </p>
            </div>
            <CardContent className="px-4 py-3 font-urbanist">
              <div className="flex items-center gap-4 py-4 pt-0 border-b">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-primary/10 shrink-0">
                  <TicketIcon className="w-6 h-6 text-black" />
                </div>
                <div className="w-full text-black ">
                  <p className="text-2xl font-bold">
                    {ticketData?.isGuestList ? "GUESTLIST - " : ""}{" "}
                    {ticketData?._tickettier?.name}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-4 py-4 cursor-pointer border-b">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-primary/10 shrink-0">
                  <ProfileIcon className="w-6 h-6 text-black" />
                </div>
                <div className="w-full text-black ">
                  <p className="text-lg font-bold">
                    {ticketData?._user?.firstName} {ticketData?._user?.lastName}
                  </p>
                  {ticketData?._user?.dob && (
                    <p className="text-base font-medium">
                      DOB: {formatBirthdate(ticketData?._user?.dob)}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-4 py-2">
                <div className="w-full text-black ">
                  <p className="text-xs font-bold">
                    {ticketData?._tickettier?.description}
                  </p>{" "}
                </div>
              </div>
            </CardContent>
          </div>

          {/* Centered button container */}
          <div className="absolute bottom-[-10%] left-1/2 transform -translate-x-1/2 w-32 h-32 rounded-full bg-white z-[3] flex justify-center items-center">
            <button
              className="p-0 border-none bg-transparent focus:outline-none w-[80%]"
              onClick={() => {
                setTicketData(null);
                setTicketStatus("");
              }}
            >
              <img
                src={scanButtonGray}
                alt="Scan button"
                className="w-full h-full"
              />
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (ticketStatus === "success") {
    return (
      <div className="h-full">
        <div
          className="relative w-[90%] h-[84%] mx-auto rounded-lg mt-4 flex"
          style={{
            border: "6px solid white",
            borderRadius: "20px",
            backgroundColor: "#3ABD47",
          }}
        >
          <div className="absolute top-0 left-0 w-full" style={{ zIndex: 100 }}>
            <div class="border border-zinc-200 dark:border-zinc-800 dark:bg-zinc-950 dark:text-zinc-50 py-5 text-white bg-gray-800 shadow-md rounded-[20px] w-[80%] mx-auto mt-4">
              <div class="flex flex-col items-center gap-4 p-0 text-center">
                <h3 class="text-xl font-semibold text-center truncate w-full max-w-[200px]">
                  {event.title}
                </h3>
              </div>
            </div>
          </div>

          <div className="w-full" style={{ marginTop: "100px" }}>
            <div
              style={{ backgroundColor: "white" }}
              className="flex mt-3 items-center justify-center w-20 h-20 rounded-full p-4 mx-auto"
            >
              <CheckIcon className="w-16 h-16" style={{ color: "#3ABD47" }} />
            </div>{" "}
            <CardContent className="px-4 py-3 font-urbanist">
              <div className="flex items-center gap-4 py-4 pt-0 border-b">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-primary/10 shrink-0">
                  <TicketIcon className="w-6 h-6 text-black" />
                </div>
                <div className="w-full text-black ">
                  <p className="text-2xl font-bold">
                    {ticketData?.isGuestList ? "GUESTLIST - " : ""}{" "}
                    {ticketData?._tickettier?.name}
                  </p>{" "}
                </div>
              </div>
              <div className="flex items-center gap-4 py-4 cursor-pointer border-b">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-primary/10 shrink-0">
                  <ProfileIcon className="w-6 h-6 text-black" />
                </div>
                <div className="w-full text-black ">
                  <p className="text-lg font-bold">
                    {ticketData?._user?.firstName} {ticketData?._user?.lastName}
                  </p>
                  {ticketData?._user?.dob && (
                    <p className="text-base font-medium">
                      DOB: {formatBirthdate(ticketData?._user?.dob)}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-4 py-2">
                <div className="w-full text-black ">
                  <p className="text-l font-bold">
                    {ticketData?._tickettier?.description}
                  </p>{" "}
                </div>
              </div>
            </CardContent>
          </div>

          {/* Centered button container */}
          <div className="absolute bottom-[-10%] left-1/2 transform -translate-x-1/2 w-32 h-32 rounded-full bg-white z-[3] flex justify-center items-center">
            <button
              className="p-0 border-none bg-transparent focus:outline-none w-[80%]"
              onClick={() => {
                setTicketData(null);
                setTicketStatus("");
              }}
            >
              <img
                src={scanButtonGray}
                alt="Scan button"
                className="w-full h-full"
              />
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (scan) {
    return (
      <div className="h-full">
        <div
          className="relative w-[90%] h-[84%] mx-auto rounded-lg mt-4"
          style={{ border: "6px solid white", borderRadius: "20px" }}
        >
          {/* Loading spinner overlay */}
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 z-[2] rounded-lg">
              <div className="w-16 h-16 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
            </div>
          )}

          <div className="absolute top-0 left-0 w-full" style={{ zIndex: 100 }}>
            <div class="border border-zinc-200 dark:border-zinc-800 dark:bg-zinc-950 dark:text-zinc-50 py-5 text-white bg-gray-800 shadow-md rounded-[20px] w-[80%] mx-auto mt-4">
              <div class="flex flex-col items-center gap-4 p-0 text-center">
                <h3 class="text-xl font-semibold text-center truncate w-full max-w-[200px]">
                  {event.title}
                </h3>
              </div>
            </div>
          </div>

          <video
            ref={videoRef}
            className={`absolute top-0 left-0 w-full h-full object-cover rounded-lg ${
              loading ? "hidden" : ""
            }`}
            style={{ borderRadius: "15px" }}
            autoPlay
          />

          {/* <Scanner
            onScan={(result) => console.log(result)}
            styles={{ height: "100%", width: "100%" }}
          /> */}

          {/* Scan area guide */}
          {!loading && (
            <div className="absolute top-1/2 left-1/2 w-48 h-48 z-[1] transform -translate-x-1/2 -translate-y-1/2">
              <img
                src={scanImage}
                alt="icon"
                className="absolute w-full h-full"
              />
            </div>
          )}

          {/* Centered button container */}
          <div className="absolute bottom-[-10%] left-1/2 transform -translate-x-1/2 w-32 h-32 rounded-full bg-white z-[3] flex justify-center items-center">
            <button
              className="p-0 border-none bg-transparent focus:outline-none w-[80%]"
              onClick={() => setScan(false)}
            >
              <img
                src={scanButtonPurple}
                alt="Scan button"
                className="w-full h-full"
              />
            </button>
          </div>
        </div>
      </div>
    );
  }

  tickets.sort((a, b) => {
    // Extract first name, last name, and phone number for both tickets
    const aFirstName = a._user?.firstName || "";
    const aLastName = a._user?.lastName || "";
    const aPhone = a._user?.phoneNumber || "";

    const bFirstName = b._user?.firstName || "";
    const bLastName = b._user?.lastName || "";
    const bPhone = b._user?.phoneNumber || "";

    // Sort by first name
    if (aFirstName !== bFirstName) {
      return aFirstName.localeCompare(bFirstName);
    }

    // If first names are equal, sort by last name
    if (aLastName !== bLastName) {
      return aLastName.localeCompare(bLastName);
    }

    // If first and last names are equal, sort by phone number
    return aPhone.localeCompare(bPhone);
  });

  console.log("selectedTicket:", selectedTicket);

  return (
    <div className="h-full">
      <div
        className="relative w-[90%] h-[80%] mx-auto rounded-lg mt-4 flex flex-col"
        style={{ border: "6px solid white", borderRadius: "20px" }}
      >
        {/* Loading spinner overlay */}
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 z-[2] rounded-lg">
            <div className="w-16 h-16 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
          </div>
        )}

        <div className="absolute top-0 left-0 w-full" style={{ zIndex: 100 }}>
          <div className="border border-zinc-200 dark:border-zinc-800 dark:bg-zinc-950 dark:text-zinc-50 py-5 text-white bg-gray-800 shadow-md rounded-[20px] w-[80%] mx-auto mt-4">
            <div className="flex flex-col items-center gap-4 p-0 text-center">
              <h3 className="text-xl font-semibold text-center truncate w-full max-w-[200px]">
                {event.title}
              </h3>
            </div>
          </div>
        </div>
        <button
          style={{ height: "fit-content", marginTop: "100px" }}
          className="bg-[#D3D3D3] text-black px-2 py-2 rounded font-semibold text-xs"
          onClick={() => {
            // Add your check-in logic here
            fetchEvent();
          }}
        >
          Refrescar
        </button>
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <button
            onClick={() => setSelected("invitados")}
            style={{
              flex: 1,
              padding: "10px",
              backgroundColor: "transparent",
              border: "none",
              borderBottom:
                selected === "invitados"
                  ? "3px solid #7C5DF8"
                  : "3px solid transparent",
              color: selected === "invitados" ? "#7C5DF8" : "grey",
              fontWeight: selected === "invitados" ? "bold" : "normal",
              cursor: "pointer",
              borderRadius: "0px",
            }}
          >
            lista de invitados
          </button>
          <button
            onClick={() => setSelected("estadisticas")}
            style={{
              flex: 1,
              padding: "10px",
              backgroundColor: "transparent",
              border: "none",
              borderBottom:
                selected === "estadisticas"
                  ? "3px solid #7C5DF8"
                  : "3px solid transparent",
              color: selected === "estadisticas" ? "#7C5DF8" : "grey",
              fontWeight: selected === "estadisticas" ? "bold" : "normal",
              cursor: "pointer",
              borderRadius: "0px",
            }}
          >
            estadísticas
          </button>
        </div>
        {selected === "estadisticas" ? (
          <TicketStats />
        ) : (
          <div
            className="flex flex-col gap-2 px-4 mx-auto"
            style={{
              overflow: "scroll",
              marginTop: "10px",
              width: "100%",
              paddingBottom: "100px",
              flex: 1,
            }}
          >
            {tickets.map((ticket) => {
              const isChecked = ticket.status !== "purchased";
              return (
                <div
                  className="flex items-center gap-4 pb-4 border-b border-grey-400"
                  key={ticket._id}
                >
                  <div>
                    {selectedTicket !== ticket._id && (
                      <div
                        onClick={() => {
                          if (isChecked) return;
                          setSelectedTicket(ticket._id);
                        }}
                        className={cn(
                          "!w-8 cursor-pointer !h-8 rounded-full flex items-center justify-center shrink-0 border-2",
                          isChecked
                            ? "bg-success border-success"
                            : "bg-transparent border-black"
                        )}
                      >
                        {isChecked ? (
                          <CheckIcon className="w-6 h-6 text-white" />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {selectedTicket === ticket._id && (
                      <div
                        onClick={() => {
                          setSelectedTicket(null);
                        }}
                        style={{ backgroundColor: "#7C5DF8" }}
                        className="!w-8 cursor-pointer !h-8 rounded-full flex items-center justify-center shrink-0 border-2 bg-transparent border-black"
                      />
                    )}
                  </div>
                  <div className="flex justify-between w-full gap-4 items-center">
                    <div>
                      <div className="text-lg font-medium">
                        {ticket?._user?.firstName
                          ? ticket?._user?.firstName +
                            " " +
                            ticket?._user?.lastName
                          : ticket?._user?.phoneNumber}
                      </div>
                      {ticket?._user?.dob && (
                        <div className={`text-sm`}>
                          {formatBirthdate(ticket._user.dob)}
                        </div>
                      )}
                      <div>
                        <span className="text-xs font-bold">
                          {ticket?._tickettier?.name} {ticket?.transaction?.redemption_code_used ? ' - GUESTLIST' : ''}
                        </span>
                      </div>
                    </div>
                    {/* "Registrar" button only for the selected ticket */}
                    {selectedTicket === ticket._id && (
                      <button
                        style={{ height: "fit-content" }}
                        className="bg-[#7C5DF8] text-white px-2 py-2 rounded font-semibold text-xs"
                        onClick={() => {
                          // Add your check-in logic here
                          console.log("Checking in ticket:", selectedTicket);
                          handleScan(selectedTicket);
                        }}
                      >
                        Registrar
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* Centered Scan button container */}
        <div className="absolute bottom-[-10%] left-1/2 transform -translate-x-1/2 w-32 h-32 rounded-full bg-white z-[3] flex justify-center items-center">
          <button
            className="p-0 border-none bg-transparent focus:outline-none w-[80%]"
            onClick={() => setScan(true)}
          >
            <img
              src={scanButtonGray}
              alt="Scan button"
              className="w-full h-full"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScanTicket;
