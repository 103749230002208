const TicketIcon = (props) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} >
      <path d="M17.6971 7.15228C17.3072 6.7622 17.0788 6.23928 17.0578 5.68811C17.0368 5.13694 17.2246 4.59814 17.5837 4.17947C17.6134 4.14465 17.6288 4.09989 17.6269 4.05417C17.625 4.00844 17.6059 3.96513 17.5734 3.93291L15.5048 1.86197C15.4706 1.82779 15.4242 1.80859 15.3759 1.80859C15.3275 1.80859 15.2811 1.82779 15.247 1.86197L11.9498 5.15916C11.8281 5.28078 11.7365 5.42908 11.6821 5.59228V5.59228C11.6279 5.75587 11.5364 5.90458 11.4148 6.02663C11.2931 6.14868 11.1447 6.24071 10.9813 6.29541V6.29541C10.818 6.34983 10.6696 6.44146 10.5477 6.56306L2.09195 15.017C2.05777 15.0512 2.03857 15.0975 2.03857 15.1459C2.03857 15.1942 2.05777 15.2406 2.09195 15.2748L4.16054 17.3434C4.19276 17.3759 4.23608 17.395 4.2818 17.3969C4.32753 17.3988 4.37229 17.3834 4.40711 17.3537C4.82569 16.9942 5.3646 16.8061 5.91596 16.827C6.46733 16.8479 6.99045 17.0763 7.3806 17.4664C7.77075 17.8566 7.99914 18.3797 8.02005 18.9311C8.04096 19.4824 7.85284 20.0214 7.49336 20.4399C7.46366 20.4748 7.44822 20.5195 7.45013 20.5652C7.45204 20.611 7.47117 20.6543 7.50367 20.6865L9.57226 22.7551C9.60646 22.7893 9.65282 22.8085 9.70117 22.8085C9.74952 22.8085 9.79588 22.7893 9.83008 22.7551L18.2863 14.2993C18.4079 14.1775 18.4996 14.029 18.554 13.8657V13.8657C18.6081 13.7021 18.6997 13.5534 18.8213 13.4314C18.943 13.3093 19.0914 13.2173 19.2548 13.1626V13.1626C19.418 13.1082 19.5663 13.0166 19.6879 12.8949L22.9851 9.59775C23.0193 9.56356 23.0385 9.51719 23.0385 9.46885C23.0385 9.4205 23.0193 9.37413 22.9851 9.33994L20.9165 7.27135C20.8843 7.23884 20.841 7.21972 20.7952 7.2178C20.7495 7.21589 20.7047 7.23133 20.6699 7.26103C20.2518 7.62074 19.7134 7.80937 19.1622 7.78921C18.611 7.76904 18.0878 7.54158 17.6971 7.15228V7.15228Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M12.2807 6.89109L11.5068 6.11719" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M14.3442 8.95508L13.8281 8.43945" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M16.4077 11.02L15.8921 10.5039" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M18.7295 13.3403L17.9556 12.5664" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
    </svg>
    
  )
}

export default TicketIcon