const ProfileIcon = (props) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} >
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5235 15.7305C8.65588 15.7305 5.35303 16.3152 5.35303 18.6571C5.35303 20.999 8.63493 21.6048 12.5235 21.6048C16.3911 21.6048 19.693 21.019 19.693 18.6781C19.693 16.3371 16.4121 15.7305 12.5235 15.7305Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5235 12.3906C15.0616 12.3906 17.1187 10.3326 17.1187 7.79446C17.1187 5.25636 15.0616 3.19922 12.5235 3.19922C9.98537 3.19922 7.92727 5.25636 7.92727 7.79446C7.9187 10.324 9.96251 12.3821 12.4911 12.3906H12.5235Z" stroke="currentColor" strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}

export default ProfileIcon